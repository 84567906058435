/**
 * This is a modified https://github.com/backstage/backstage/blob/master/plugins/techdocs/src/home/components/TechDocsCustomHome.tsx
 * It's primary purpose is to use PageWithHeader and overwrite the title instead of using TechDocsPageWrapper
 */
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { makeStyles } from '@material-ui/core';
import {
  CATALOG_FILTER_EXISTS,
  catalogApiRef,
  CatalogApi,
  useEntityOwnership,
} from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import {
  CodeSnippet,
  Content,
  RoutedTabs,
  Progress,
  WarningPanel,
  SupportButton,
  ContentHeader,
  PageWithHeader,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { FeatureFlagged } from '@backstage/core-app-api';
import { CSSProperties } from '@material-ui/styles';
import { DocsTable, DocsCardGrid } from '@backstage/plugin-techdocs';
import { RoadmapCardGrid } from '@efg/plugin-techdoc-goldenpath-and-roadmaps';

const panels = {
  DocsTable: DocsTable,
  DocsCardGrid: DocsCardGrid,
  RoadmapCardGrid: RoadmapCardGrid,
};

export type TabsConfig = TabConfig[];

interface TabConfig {
  title: string;
  path: string;
  panels: PanelConfig[];
}

type PanelType = 'DocsCardGrid' | 'DocsTable' | 'RoadmapCardGrid';

export interface PanelConfig {
  title: string;
  description: string;
  panelType: PanelType;
  panelCSS?: CSSProperties;
  filterPredicate: ((entity: Entity) => boolean) | string;
  featureFlagged?: string;
}

const CustomPanel = ({
  config,
  entities,
  index,
}: {
  config: PanelConfig;
  entities: Entity[];
  index: number;
}) => {
  const useStyles = makeStyles({
    panelContainer: {
      marginBottom: '2rem',
      ...(config.panelCSS ? config.panelCSS : {}),
    },
  });
  const classes = useStyles();
  const { loading: loadingOwnership, isOwnedEntity } = useEntityOwnership();

  const Panel = panels[config.panelType];

  const shownEntities = entities.filter(entity => {
    if (config.filterPredicate === 'ownedByUser') {
      if (loadingOwnership) {
        return false;
      }
      return isOwnedEntity(entity);
    }

    return (
      typeof config.filterPredicate === 'function' &&
      config.filterPredicate(entity)
    );
  });

  return (
    <>
      <ContentHeader title={config.title} description={config.description}>
        {index === 0 ? (
          <SupportButton>
            Discover documentation in your ecosystem.
          </SupportButton>
        ) : null}
      </ContentHeader>
      <div className={classes.panelContainer}>
        <Panel data-testid="techdocs-custom-panel" entities={shownEntities} />
      </div>
    </>
  );
};

type EFGCustomHomeProps = {
  title: React.ReactNode;
  tabsConfig: TabsConfig;
};

type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: Function;
  children: JSX.Element;
};

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) => (condition ? wrapper(children) : children);

export const EFGCustomTechDocsHome = (props: EFGCustomHomeProps) => {
  const { tabsConfig } = props;
  const catalogApi: CatalogApi = useApi(catalogApiRef);

  const {
    value: entities,
    loading,
    error,
  } = useAsync(async () => {
    const response = await catalogApi.getEntities({
      filter: {
        'metadata.annotations.backstage.io/techdocs-ref': CATALOG_FILTER_EXISTS,
      },
      fields: [
        'apiVersion',
        'kind',
        'metadata',
        'relations',
        'spec.owner',
        'spec.type',
      ],
    });
    return response.items.filter((entity: Entity) => {
      return !!entity.metadata.annotations?.['backstage.io/techdocs-ref'];
    });
  });

  if (loading) {
    return (
      <PageWithHeader title={props.title} themeId="documentation">
        <Content>
          <Progress />
        </Content>
      </PageWithHeader>
    );
  }

  if (error) {
    return (
      <PageWithHeader title={props.title} themeId="documentation">
        <Content>
          <WarningPanel
            severity="error"
            title="Could not load available documentation."
          >
            <CodeSnippet language="text" text={error.toString()} />
          </WarningPanel>
        </Content>
      </PageWithHeader>
    );
  }

  return (
    <PageWithHeader title={props.title} themeId="documentation">
      <RoutedTabs
        routes={tabsConfig.map(tab => ({
          title: tab.title,
          path: tab.path,
          children: (
            <Content data-testid="techdocs-content">
              {tab.panels.map((config, index) => (
                <ConditionalWrapper
                  condition={!!config.featureFlagged}
                  wrapper={(children: JSX.Element) => (
                    <FeatureFlagged with={config.featureFlagged!}>
                      {children}
                    </FeatureFlagged>
                  )}
                >
                  <CustomPanel
                    config={config}
                    entities={!!entities ? entities : []}
                    index={index}
                  />
                </ConditionalWrapper>
              ))}
            </Content>
          ),
        }))}
      />
    </PageWithHeader>
  );
};
