import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  analyticsApiRef,
  identityApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import {
  techdocsStorageApiRef,
  techdocsApiRef,
} from '@backstage/plugin-techdocs-react';
import { visitsApiRef, VisitsWebStorageApi } from '@backstage/plugin-home';
import { GoogleAnalytics4 } from '@backstage-community/plugin-analytics-module-ga4';

import {
  roadmapStorageApiRef,
  RoadmapStorageApiClient,
} from '@efg/plugin-techdoc-goldenpath-and-roadmaps';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: roadmapStorageApiRef,
    deps: {
      techdocsStorageApi: techdocsStorageApiRef,
      techdocApi: techdocsApiRef,
    },
    factory: ({ techdocsStorageApi, techdocApi }) =>
      new RoadmapStorageApiClient({ techdocsStorageApi, techdocApi }),
  }),
  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) =>
      GoogleAnalytics4.fromConfig(configApi, {
        identityApi,
      }),
  }),
  createApiFactory({
    api: visitsApiRef,
    deps: {
      identityApi: identityApiRef,
      errorApi: errorApiRef,
    },
    factory: ({ identityApi, errorApi }) =>
      VisitsWebStorageApi.create({ identityApi, errorApi }),
  }),
];
