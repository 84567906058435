import { homePlugin, Tool } from '@backstage/plugin-home';
import React from 'react';
import Description from '@material-ui/icons/Description';
import NoteAdd from '@material-ui/icons/NoteAdd';
import Publish from '@material-ui/icons/Publish';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import { CardExtensionProps } from '@backstage/plugin-home-react';
import { createReactExtension } from '@backstage/core-plugin-api';

export function backstageDocsContent(baseUrl: string | undefined) {
  const title = 'Backstage Intro Docs';
  const tools: Tool[] = [
    {
      url: `${baseUrl}/docs/default/component/backstage/efg-software-catalog/kinds-and-types/`,
      label: 'EFG Software Catalog - Kinds and Types',
      icon: <VerticalSplitIcon />,
    },
    {
      url: `${baseUrl}/docs/default/component/backstage//efg-software-catalog/importing-entities`,
      label: 'EFG Software Catalog - Importing Entities',
      icon: <Publish />,
    },
    {
      url: `${baseUrl}/docs/default/component/backstage//efg-software-catalog/annotations`,
      label: 'EFG Software Catalog - Supported Annotations',
      icon: <NoteAdd />,
    },
    {
      url: `https://backstage.io/docs/features/software-catalog/software-catalog-overview`,
      label: 'backstage.io - Software Catalog',
      icon: <Description />,
    },
    {
      url: `https://backstage.io/docs/features/software-catalog/descriptor-format`,
      label: 'backstage.io - Entities/Kinds',
      icon: <Description />,
    },
    {
      url: `https://backstage.io/docs/features/software-catalog/system-model`,
      label: 'backstage.io - System Model',
      icon: <Description />,
    },
  ];
  return { title, tools };
}

export const BackstageDocs = homePlugin.provide(
  createReactExtension<CardExtensionProps<any>>({
    name: 'BackstageDocs',
    component: {
      lazy: () => import('@backstage/plugin-home').then(m => m.HomePageToolkit),
    },
  }),
);
