import {
  GroupsExplorerContent,
  ToolExplorerContent,
  ExploreLayout,
} from '@backstage-community/plugin-explore';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

import React from 'react';
import { TeamExplorerContent } from './TeamsContent';

export const ExplorePage = () => {
  const configApi = useApi(configApiRef);
  const organizationName =
    configApi.getOptionalString('organization.name') ?? 'Backstage';

  return (
    <ExploreLayout
      title={`Explore the ${organizationName} ecosystem`}
      subtitle="Browse our ecosystem"
    >
      <ExploreLayout.Route path="teams" title="Teams">
        <TeamExplorerContent />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="groups" title="Org Chart">
        <GroupsExplorerContent title="Teams and Business Efforts" />
      </ExploreLayout.Route>
      {/* <ExploreLayout.Route path="domains" title="Domains">*/}
      {/*  <DomainExplorerContent />*/}
      {/* </ExploreLayout.Route>*/}
      <ExploreLayout.Route path="tools" title="Tools">
        <ToolExplorerContent />
      </ExploreLayout.Route>
    </ExploreLayout>
  );
};

export const explorePage = <ExplorePage />;
