import {
  CustomMapping,
  EntityFilterGroup,
  extendableCortexPlugin,
  ExtensionApi,
} from '@cortexapps/backstage-plugin';
import {
  Entity,
  isSystemEntity,
  isComponentEntity,
  ComponentEntityV1alpha1,
} from '@backstage/catalog-model';
import { EntityFilter } from '@cortexapps/backstage-plugin-extensions';

class ExtensionApiImpl implements ExtensionApi {
  async getAdditionalFilters(): Promise<EntityFilterGroup[]> {
    return [
      {
        name: 'Type',
        groupProperty: (entity: Entity) =>
          entity.spec?.type === null || entity.spec?.type === undefined
            ? undefined
            : [JSON.stringify(entity.spec?.type).replaceAll('"', '')],
      },
    ];
  }

  async getCustomMappings(): Promise<CustomMapping[]> {
    return [
      (entity: Entity) => {
        if (isComponentEntity(entity)) {
          const component = entity as ComponentEntityV1alpha1;
          const system = component.spec.system;
          const extraServiceGroups = system ? [`system:${system}`] : [];
          return {
            'x-cortex-service-groups': [
              ...(component.metadata.tags ?? []),
              ...extraServiceGroups,
            ],
            'x-cortex-type': 'component',
            'x-cortex-definition': [],
          };
        } else if (isSystemEntity(entity)) {
          return {
            'x-cortex-service-groups': [...(entity.metadata.tags ?? [])],
            'x-cortex-type': 'system',
            'x-cortex-definition': [],
          };
        }
        // we do not want to specify any type so Cortex picks
        return {
          'x-cortex-service-groups': [...(entity.metadata.tags ?? [])],
        };
      },
    ];
  }

  async getSyncEntityFilter(): Promise<EntityFilter> {
    return {
      kinds: ['Domain', 'Component', 'System', 'User', 'Group'],
      entityFilter: (entity: Entity) => {
        if (isComponentEntity(entity)) {
          // we do not want anticheat components for now
          if (
            entity.spec?.owner !== null &&
            entity.spec.owner === 'group:team/anticheat'
          ) {
            return false;
          }

          // only services are exported to Cortex
          return entity.spec?.type === null || entity.spec?.type === undefined
            ? false
            : entity.spec.type === 'service';
        }

        // everything else is fine
        return true;
      },
    };
  }
}

export const {
  plugin,
  EntityCortexContent,
  CortexPage,
  CortexScorecardWidget,
} = extendableCortexPlugin({}, () => new ExtensionApiImpl());
