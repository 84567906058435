import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  logo: {
    width: 'auto',
    height: 35,
  },
  container: {
    display: 'flex',
  },
  title: {
    lineHeight: '1.4',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <img
        className={classes.logo}
        src="/EFG_Logo_Black_RGB.svg"
        alt="EFG Logo"
      />
      {/* this is included because the text and line height keeps styling consistent */}
      <Box ml={1}>
        <Typography variant="h5" className={classes.title}>
          &nbsp;
        </Typography>
      </Box>
    </Grid>
  );
};

export default LogoIcon;
