import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';

export const DefaultNotAuthorized = (
  <Container maxWidth="sm">
    <Box mt={10}>
      <Typography variant="h5" align="center">
        Page Not Authorized
      </Typography>
      <Typography variant="body1" align="center">
        You are not authorized to access this page.
      </Typography>
    </Box>
  </Container>
);
