import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'techdoc-roadmaps-and-goldenpath',
});

export const rootEditorRouteRef = createRouteRef({
  id: 'techdoc-roadmaps-and-goldenpath-editor',
  params: ['namespace', 'kind', 'name'],
});

export const rootViewerRouteRef = createRouteRef({
  id: 'techdoc-roadmaps-and-goldenpath-viewer',
  params: ['namespace', 'kind', 'name'],
});
