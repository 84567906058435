import { IconLinkVerticalProps } from '@backstage/core-components';
import React from 'react';
import { Entity } from '@backstage/catalog-model';
import {
  grafana as Grafana,
  slack as Slack,
} from '../../assets/icons/CustomIcons';

export interface AboutCardHeaderIconLinksProps {
  entity: Entity;
}

// Function to get the link URL by type
function getLinkByType(entity: Entity, type: string): string | undefined {
  const link = entity.metadata.links?.find(l => l.type === type);
  return link ? link.url : undefined;
}

export function customAboutCardHeaderIconLinks(
  props: AboutCardHeaderIconLinksProps,
): IconLinkVerticalProps[] {
  // The disable effect is very bad I had rather not seeing the icon than a confusing clickable icon
  const headersIcons = [];

  const slackLink = getLinkByType(props.entity, 'slack');
  if (slackLink !== undefined) {
    const viewInSlack: IconLinkVerticalProps = {
      label: 'Slack',
      disabled: slackLink === undefined,
      icon: <Slack />,
      href: slackLink,
    };
    headersIcons.push(viewInSlack);
  }

  const grafanaLink = getLinkByType(props.entity, 'grafana');
  if (grafanaLink !== undefined) {
    const viewInGrafana: IconLinkVerticalProps = {
      label: 'Grafana',
      disabled: grafanaLink === undefined,
      icon: <Grafana />,
      href: grafanaLink,
    };
    headersIcons.push(viewInGrafana);
  }

  return headersIcons;
}
