import React from 'react';
import { Box, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import { lightEFGPalette, darkEFGPalette } from '../../theme/efg';

const useStyles = makeStyles({
  logo: {
    width: 'auto',
    height: 35,
  },
  container: {
    display: 'flex',
  },
  title: {
    lineHeight: '1.5',
  },
  dark: {
    color: darkEFGPalette?.navigation?.color,
  },
  light: {
    color: lightEFGPalette?.navigation?.color,
  },
});

const LogoFull = () => {
  const paletteType = useTheme().palette.type;
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <img
        className={classes.logo}
        src="/EFG_Logo_Black_RGB.svg"
        alt="EFG Logo"
      />
      <Box ml={1}>
        <Typography
          variant="h5"
          className={`${classes.title} ${classes[paletteType]}`}
        >
          BACKSTAGE
        </Typography>
      </Box>
    </Grid>
  );
};

export default LogoFull;
