import { Entity } from '@backstage/catalog-model';
import { useRouteRef } from '@backstage/core-plugin-api';
import {
  LinkButton,
  ItemCardGrid,
  ItemCardHeader,
} from '@backstage/core-components';
import { Card, CardActions, CardContent, CardMedia } from '@material-ui/core';
import React from 'react';
import { entityRouteRef } from '@backstage/plugin-catalog-react';

/**
 * Props for {@link DocsCardGrid}
 *
 * @public
 */
export type RoadmapCardGridProps = {
  entities: Entity[] | undefined;
};

/**
 * Component which accepts a list of entities and renders a item card for each entity
 *
 * @public
 */
export const RoadmapCardGrid = (props: RoadmapCardGridProps) => {
  const { entities } = props;
  const getRouteToViewerPageFor = useRouteRef(entityRouteRef);
  if (!entities) return null;
  return (
    <ItemCardGrid data-testid="docs-explore">
      {!entities?.length
        ? null
        : entities.map((entity, index: number) => (
            <Card key={index}>
              <CardMedia>
                <ItemCardHeader
                  title={entity.metadata.title ?? entity.metadata.name}
                />
              </CardMedia>
              <CardContent>{entity.metadata.description}</CardContent>
              <CardActions>
                <LinkButton
                  to={`${getRouteToViewerPageFor({
                    kind: entity.kind,
                    name: entity.metadata.name,
                    namespace: entity.metadata.namespace ?? 'default',
                  })}/roadmap`}
                  color="primary"
                  data-testid="read_docs"
                >
                  Take Me There
                </LinkButton>
              </CardActions>
            </Card>
          ))}
    </ItemCardGrid>
  );
};
