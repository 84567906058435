import {
  createPlugin,
  createComponentExtension,
} from '@backstage/core-plugin-api';

/** @public */
export const orgWithManagerPlugin = createPlugin({
  id: 'org-with-manager',
});

/** @public */
export const EntityGroupProfileWithManager = orgWithManagerPlugin.provide(
  createComponentExtension({
    name: 'EntityGroupProfileWithManager',
    component: {
      lazy: () => import('./components').then(m => m.GroupProfileCard),
    },
  }),
);

/** @public */
export const EntityMembersListWithManager = orgWithManagerPlugin.provide(
  createComponentExtension({
    name: 'EntityMembersListWithManager',
    component: {
      lazy: () => import('./components').then(m => m.MembersListCard),
    },
  }),
);
