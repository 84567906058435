import { homePlugin, type Tool } from '@backstage/plugin-home';
import { type CardExtensionProps } from '@backstage/plugin-home-react';
import React from 'react';
import Add from '@material-ui/icons/Add';
import Group from '@material-ui/icons/Group';
import Code from '@material-ui/icons/Code';
import { createReactExtension } from '@backstage/core-plugin-api';
import { Jenkins } from '../../../assets/icons/CustomIcons';

export const usefulLinksContent = (baseUrl: string | undefined) => {
  const title = 'Useful Links';
  const tools: Tool[] = [
    {
      url: `${baseUrl}/catalog-import`,
      label: 'Add Your Project',
      icon: <Add />,
    },
    {
      url: `${baseUrl}/catalog?filters[kind]=group&filters[type]=team&filters[user]=all`,
      label: 'Our Teams',
      icon: <Group />,
    },
    {
      url: `https://gitlab.com/eslfaceitgroup`,
      label: 'Gitlab',
      icon: <Code />,
    },
    {
      url: `https://jenkins.faceit-infra.com/`,
      label: 'FACEIT Jenkins',
      icon: <Jenkins />,
    },
  ];
  return { title, tools };
};

export const UsefulLinks = homePlugin.provide(
  createReactExtension<CardExtensionProps<any>>({
    name: 'UsefulLinks',
    component: {
      lazy: () => import('@backstage/plugin-home').then(m => m.HomePageToolkit),
    },
  }),
);
