import { IconButton } from '@material-ui/core';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import React from 'react';
import { Link } from '@backstage/core-components';
import { Entity } from '@backstage/catalog-model';

export function aboutCardCreateIncidentActions(entity: Entity) {
  const affectedComponent = entity.metadata.name;
  const incidentCreateURL = `https://app.incident.io/~/incidents?createIncident=true&custom_field_Affected+Component=${affectedComponent}`;

  return (
    <IconButton
      component={Link}
      aria-label="Create Incident"
      disabled={false}
      title="Create Incident"
      color="secondary"
      to={incidentCreateURL}
    >
      <AlarmAddIcon color="secondary" />
    </IconButton>
  );
}
