import { createReactExtension } from '@backstage/core-plugin-api';
import { homePlugin } from '@backstage/plugin-home';

export const EFGLogoExtension = homePlugin.provide(
  createReactExtension({
    name: 'EFGLogo',
    component: {
      lazy: () =>
        import('@backstage/plugin-home').then(m => m.HomePageCompanyLogo),
    },
    data: {
      'home.logo.config': {
        title: 'EFG Logo',
        layout: {
          height: { defaultColumns: 2, defaultRows: 12 },
          resizable: false,
        },
      },
    },
  }),
);
