import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
} from '@backstage/core-plugin-api';
import {
  techdocsStorageApiRef,
  techdocsApiRef,
} from '@backstage/plugin-techdocs-react';

import { rootEditorRouteRef, rootViewerRouteRef, rootRouteRef } from './routes';
import { TECHDOCS_ROADMAPS_ANNOTATION } from './annotations';
import { Entity } from '@backstage/catalog-model';
import {
  RoadmapStorageApiClient,
  roadmapStorageApiRef,
} from './api/RoadmapStorageApi';

export const isRoadmapAnnotationAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[TECHDOCS_ROADMAPS_ANNOTATION]);

export const techdocRoadmapsAndGoldenpathPlugin = createPlugin({
  id: 'techdoc-roadmaps-and-goldenpath',
  routes: {
    root: rootRouteRef,
    viewer: rootViewerRouteRef,
    editor: rootEditorRouteRef,
  },
  apis: [
    createApiFactory({
      api: roadmapStorageApiRef,
      deps: {
        techdocsStorageApi: techdocsStorageApiRef,
        techdocApi: techdocsApiRef,
      },
      factory: ({ techdocsStorageApi, techdocApi }) =>
        new RoadmapStorageApiClient({ techdocsStorageApi, techdocApi }),
    }),
  ],
});

export const TechdocRoadmapsAndGoldenpathEditorPage =
  techdocRoadmapsAndGoldenpathPlugin.provide(
    createRoutableExtension({
      name: 'TechdocRoadmapsAndGoldenpathEditorPage',
      component: () =>
        import('./components/RoadmapEditorComponent').then(
          m => m.RoadmapEditorPage,
        ),
      mountPoint: rootEditorRouteRef,
    }),
  );

export const TechdocRoadmapsAndGoldenpathViewerPage =
  techdocRoadmapsAndGoldenpathPlugin.provide(
    createRoutableExtension({
      name: 'TechdocRoadmapsAndGoldenpathViewerPage',
      component: () =>
        import('./components/RoadmapViewerComponent').then(
          m => m.RoadmapViewerComponent,
        ),
      mountPoint: rootViewerRouteRef,
    }),
  );
