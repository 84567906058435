import { GroupEntity } from '@backstage/catalog-model';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Button } from '@material-ui/core';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import {
  Content,
  ContentHeader,
  EmptyState,
  ItemCardGrid,
  Progress,
  SupportButton,
  WarningPanel,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  entityRouteParams,
  entityRouteRef,
} from '@backstage/plugin-catalog-react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
} from '@material-ui/core';

import { LinkButton, ItemCardHeader } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

export const TeamCard = (props: { entity: GroupEntity }) => {
  const { entity } = props;

  const catalogEntityRoute = useRouteRef(entityRouteRef);
  const url = catalogEntityRoute(entityRouteParams(entity));

  return (
    <Card>
      <CardMedia>
        <ItemCardHeader
          title={entity.metadata.title}
          subtitle={entity.spec.profile?.email}
        />
      </CardMedia>
      <CardContent>
        {entity.metadata.tags?.length ? (
          <Box>
            {entity.metadata.tags.map(tag => (
              <Chip size="small" label={tag} key={tag} />
            ))}
          </Box>
        ) : null}
        {entity.metadata.name}
      </CardContent>
      <CardActions>
        <LinkButton to={url} color="primary">
          Explore
        </LinkButton>
      </CardActions>
    </Card>
  );
};
const Body = () => {
  const catalogApi = useApi(catalogApiRef);
  const {
    value: entities,
    loading,
    error,
  } = useAsync(async () => {
    const response = await catalogApi.getEntities({
      filter: { kind: 'group', 'spec.type': 'team' },
    });
    return response.items as GroupEntity[];
  }, [catalogApi]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return (
      <WarningPanel severity="error" title="Could not load teams.">
        {error.message}
      </WarningPanel>
    );
  }

  if (!entities?.length) {
    return (
      <EmptyState
        missing="info"
        title="No teams to display"
        description="You haven't added any teams yet."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/descriptor-format#kind-group"
          >
            Read more
          </Button>
        }
      />
    );
  }

  return (
    <ItemCardGrid>
      {entities.map((entity, index) => (
        <TeamCard key={index} entity={entity} />
      ))}
    </ItemCardGrid>
  );
};

export const TeamExplorerContent = (props: { title?: string }) => {
  return (
    <Content noPadding>
      <ContentHeader title={props.title ?? 'Teams'}>
        <SupportButton>Discover the teams in your ecosystem.</SupportButton>
      </ContentHeader>
      <Body />
    </Content>
  );
};
