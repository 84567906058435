import {
  Content,
  ContentHeader,
  Header,
  Page,
  SupportButton,
} from '@backstage/core-components';
import { Grid } from '@material-ui/core';
import {
  ImportInfoCard,
  ImportStepper,
} from '@backstage/plugin-catalog-import';
import React from 'react';

export const CustomImportFlow = () => {
  const appTitle = 'Backstage';

  return (
    <Page themeId="home">
      <Header title="Register an existing component" />
      <Content>
        <ContentHeader title={`Start tracking your component in ${appTitle}`}>
          <SupportButton>
            {`Start tracking your component in ${appTitle} by adding it to the software catalog.`}
          </SupportButton>
        </ContentHeader>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={7} xl={7}>
            <ImportInfoCard exampleLocationUrl="https://gitlab.com/eslfaceitgroup/new-service/-/blob/master/backstage.yaml" />
          </Grid>

          <Grid item xs={12} md={12} lg={5} xl={5}>
            <ImportStepper initialUrl="https://gitlab.com/eslfaceitgroup/new-service/-/blob/master/backstage.yaml" />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
